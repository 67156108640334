@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,700;1,400;1,500&display=swap");
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.display-grid-list img {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  cursor: pointer;
}

header {
  background: rgba(20, 48, 93, 0.95);
  color: rgb(226, 231, 238);
  display: flex;
  padding: 1rem;
  justify-content: center;
  position: fixed;
  z-index: 10;
  width: 100%;
}
header .header-container {
  display: flex;
  align-items: center;
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  header .header-container {
    width: 800px;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  header .header-container {
    width: 1100px;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  header .header-container {
    width: 1314px;
  }
}
@media (min-width: 1920px) {
  header .header-container {
    width: 1664px;
  }
}
header .header-logo {
  font-size: var(--fs-800);
  font-family: "EB Garamond", serif;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: rgb(226, 231, 238);
  font-weight: 500;
}
header .header-logo img {
  max-height: 80px;
}
@media (orientation: portrait) and (max-width: 600px) {
  header .header-logo img {
    max-height: 50px;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  header .header-logo img {
    max-height: 40px;
  }
}
header .header-nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
}
@media (orientation: portrait) and (max-width: 600px) {
  header .header-nav-menu {
    display: none;
  }
}
header .nav-item .nav-link {
  font-size: var(--fs-200);
  color: rgb(226, 231, 238);
  text-decoration: none;
}
header .nav-item .nav-link img {
  height: 20px;
  width: 20px;
}
header .nav-item .active {
  font-weight: bold;
}
@media (orientation: portrait) and (max-width: 600px) {
  header .nav-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}

.footer {
  background: rgba(20, 48, 93, 0.95);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5em;
  color: hsl(0, 0%, 80%);
  align-items: center;
  z-index: 9;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
  gap: 0.5rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .footer {
    height: 7em;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .footer {
    height: 3em;
  }
}

.footer-nav-menu {
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  justify-content: space-evenly;
  margin-bottom: 0.5rem;
  z-index: 20;
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .footer-nav-menu {
    display: none;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .footer-nav-menu {
    display: none;
  }
}
@media (min-width: 1920px) {
  .footer-nav-menu {
    display: none;
  }
}
@media (min-width: 1440px) {
  .footer-nav-menu {
    display: none;
  }
}
.footer-nav-menu .nav-item .nav-link {
  font-size: var(--fs-200);
  color: rgb(226, 231, 238);
  text-decoration: none;
}
.footer-nav-menu .nav-item .nav-link img {
  height: 20px;
  width: 20px;
}
.footer-nav-menu .nav-item .active {
  font-weight: bold;
}
@media (orientation: portrait) and (max-width: 600px) {
  .footer-nav-menu .nav-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}

.copyright {
  font-size: var(--fs-70);
}
@media (min-width: 1440px) {
  .copyright {
    font-size: var(--fs-300);
  }
}

.site-design-bcp {
  font-size: var(--fs-50);
}
.site-design-bcp a {
  color: hsl(0, 0%, 60%);
}
@media (min-width: 1440px) {
  .site-design-bcp {
    font-size: var(--fs-50);
  }
}

.welcome-text {
  position: absolute;
  bottom: 25%;
  left: 2rem;
  color: rgb(226, 231, 238);
  font-weight: 200;
  line-height: var(--fs-700);
  background-color: rgba(204, 204, 204, 0.3);
  padding: 1rem;
  border-radius: 1rem;
}
.welcome-text .logo {
  font-size: var(--fs-700);
  font-family: "EB Garamond", serif;
}
@media (orientation: portrait) and (max-width: 600px) {
  .welcome-text {
    font-size: var(--fs-300);
  }
}

.hero-container {
  display: grid;
  position: relative;
  margin-top: 8rem;
  grid-template-columns: repeat(5, minmax(265px, 480px));
  grid-template-rows: repeat(2, minmax(200px, 360px));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .hero-container {
    width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin-top: 8rem;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .hero-container {
    margin-top: 10em;
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 220px);
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .hero-container {
    margin-top: 5rem;
    width: 95%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 175px);
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .hero-container {
    margin-top: 9em;
    width: 95%;
    grid-template-rows: repeat(2, 200px);
    grid-template-columns: repeat(5, minmax(240px, 480px));
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .hero-container {
    grid-template-columns: repeat(5, 250px);
    grid-template-rows: repeat(2, 188px);
  }
}
@media (min-width: 1920px) {
  .hero-container {
    grid-template-columns: repeat(5, 320px);
    grid-template-rows: repeat(2, 240px);
  }
}

.hero-sidebar {
  border-radius: 0.5rem;
  border: 2px solid hsl(0, 0%, 20%);
  padding: 0.5rem;
  overflow: hidden;
  background-color: rgb(226, 231, 238);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.24);
  box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.24);
  position: relative;
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .hero-sidebar {
    padding: 0.5rem;
  }
}
.hero-sidebar .container-heading {
  text-align: left;
  color: #14305d;
  font-weight: bold;
  font-family: "EB Garamond", serif;
  border-bottom: 2px solid #14305d;
  margin-bottom: 0.4rem;
}
.hero-sidebar .item {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.hero-sidebar .item .item-image img {
  width: auto;
  height: auto;
  max-height: 6rem;
}
.hero-sidebar .item .item-details {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  height: 90%;
}
.hero-sidebar .item .item-details .item-heading {
  display: flex;
  justify-content: space-between;
  color: hsl(217, 65%, 32%);
  font-family: "EB Garamond", serif;
  align-items: baseline;
  padding: 0 1rem;
}
.hero-sidebar .item .item-details .item-heading .item-title {
  font-size: var(--fs-400);
  font-weight: bold;
}
.hero-sidebar .item .item-details .item-heading .item-price {
  font-size: var(--fs-400);
}
.hero-sidebar .item .item-details .item-heading .item-price::before {
  content: "$";
}
.hero-sidebar .item .item-details .item-text {
  text-align: left;
  padding: 0 1rem;
  font-size: var(--fs-100);
  height: 75%;
}
.hero-sidebar .item .item-details .item-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.read-more-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.latestItemForSale {
  grid-area: 2/4/3/6;
}
@media (orientation: portrait) and (max-width: 600px) {
  .latestItemForSale {
    grid-area: 3/1/4/2;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .latestItemForSale {
    grid-area: 3/2/4/3;
  }
}

.siteSpecial {
  grid-area: 1/4/2/6;
}
@media (orientation: portrait) and (max-width: 600px) {
  .siteSpecial {
    grid-area: 2/1/3/2;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .siteSpecial {
    grid-area: 3/1/4/2;
  }
}

.heroSlider {
  width: 100%;
  grid-area: 1/1/3/4;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  -webkit-box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.24);
  box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.24);
}
@media (orientation: portrait) and (max-width: 600px) {
  .heroSlider {
    grid-area: 1/1/2/2;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .heroSlider {
    grid-area: 1/1/3/3;
  }
}

.heroSlider-track {
  height: 100%;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.heroSlider-images {
  display: flex;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  transition: left 1000ms ease;
}

.heroSlider-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 1500ms ease;
}
.heroSlider-image img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.heroSlider-image .hero-image-caption {
  position: absolute;
  bottom: 10%;
  color: rgb(226, 231, 238);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: var(--fs-400);
  font-weight: 200;
  font-style: italic;
  opacity: 0;
  transition: opacity 1500ms ease;
  transition-delay: 1500ms;
}
@media (orientation: portrait) and (max-width: 600px) {
  .heroSlider-image .hero-image-caption {
    font-size: var(--fs-100);
    bottom: 15%;
  }
}
.heroSlider-image .hero-image-caption-active {
  opacity: 1;
}

.heroSlider-image-active {
  opacity: 1;
  transition: opacity 1500ms ease;
}

.noTransition {
  transition: none !important;
}

.slider-circles {
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  bottom: 3%;
  width: 100%;
  z-index: 3;
}
.slider-circles .slider-circle {
  height: 1.25rem;
  width: 1.25rem;
  opacity: 0.7;
  background-color: hsl(0, 0%, 20%);
  border: 1px solid hsl(0, 0%, 40%);
  border-radius: 50%;
}
.slider-circles .slider-circle:hover {
  cursor: pointer;
}
.slider-circles .slider-circle-active {
  background-color: hsl(0, 0%, 30%);
  border: 1px solid hsl(0, 0%, 80%);
}

.news-container {
  max-width: 1664px;
}
@media (orientation: portrait) and (max-width: 600px) {
  .news-container {
    max-width: unset;
    width: 90%;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .news-container {
    max-width: unset;
    width: 90%;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .news-container {
    max-width: 1314px;
  }
}
@media (min-width: 1920px) {
  .news-container {
    width: 1664px;
  }
}

.latestNewsItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.latestNewsItem .container-heading {
  color: hsl(217, 65%, 32%);
  font-family: "EB Garamond", serif;
  font-weight: bold;
  border-bottom: 1px solid hsl(217, 65%, 52%);
}
.latestNewsItem .item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
}
.latestNewsItem .item .item-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.latestNewsItem .item .item-heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media (orientation: portrait) and (max-width: 600px) {
  .latestNewsItem .item .item-heading {
    flex-direction: column;
  }
}
.latestNewsItem .item .item-title {
  font-size: var(--fs-400);
  color: hsl(217, 65%, 32%);
}
.latestNewsItem .item .item-date {
  color: hsl(217, 65%, 32%);
  font-style: italic;
}
.latestNewsItem .item .item-image img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.news-items-container {
  padding-top: 1rem;
  display: grid;
  width: 100%;
  gap: 1rem;
  padding-bottom: 5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 33%);
  grid-template-areas: "main main" "main main" "one two " "three four" ". .";
}
@media (orientation: portrait) and (max-width: 600px) {
  .news-items-container {
    grid-template-areas: "main main" "one two " "three four" ". .";
    grid-template-rows: repeat(auto-fill, 10%);
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .news-items-container {
    grid-template-areas: "main main" "main main" "one two " "three four" ". .";
    grid-template-rows: unset;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .news-items-container {
    grid-template-areas: "main main" "main main" "one two " "three four" ". .";
    grid-template-rows: unset;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .news-items-container {
    grid-template-columns: repeat(5, 250px);
    grid-template-rows: repeat(3, 230px);
    grid-template-areas: "main main main one two" "main main main three four" ". . . . .";
  }
}
@media (min-width: 1920px) {
  .news-items-container {
    grid-template-columns: repeat(5, 320px);
    grid-template-rows: repeat(3, 250px);
    grid-template-areas: "main main main one two" "main main main three four" ". . . . .";
  }
}

.first-news-item {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 7rem;
}

.news-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: hsl(0, 0%, 20%);
}
.news-item img {
  height: 200px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .news-item img {
    height: 100%;
  }
}

.news-item-main img {
  width: 90%;
  height: auto;
  border-radius: 1rem;
}

.news-item-1 {
  grid-area: one;
}

.news-item-2 {
  grid-area: two;
}

.news-item-3 {
  grid-area: three;
}

.news-item-4 {
  grid-area: four;
}

.brands-container {
  width: 100%;
  max-width: 1664px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brands-container {
    max-width: unset;
    width: 90%;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brands-container {
    max-width: unset;
    width: 90%;
  }
}
@media (min-width: 1440px) and (max-width: 1919px) {
  .brands-container {
    max-width: 1314px;
  }
}
@media (min-width: 1920px) {
  .brands-container {
    width: 1664px;
  }
}
.brands-container .brand-filters {
  display: flex;
  gap: 5rem;
}
.brands-container .brand-filters .brand-filter {
  color: hsl(0, 0%, 40%);
  font-weight: 500;
  transition: all 350ms ease-in;
  cursor: pointer;
}
.brands-container .brand-filters .brand-filter-active {
  color: hsl(217, 65%, 32%);
  text-shadow: 1px 0 hsl(0, 0%, 40%);
  transition: all 350ms ease-out;
  cursor: default;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brands-container .brand-filters {
    gap: 1rem;
  }
}

.brand-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 270px;
  gap: 1rem;
  opacity: 1;
  transition: opacity 350ms ease;
  margin-bottom: 3em;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-list {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 170px;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brand-list {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
  }
}

.brand-list-transitioning {
  opacity: 0;
}

.brand-card {
  background: #14305d;
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid #14305d;
}
.brand-card .brand-logo {
  max-width: 80%;
  max-height: 30%;
  position: absolute;
  top: 1rem;
}
.brand-card .brand-image {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.brand-card .brand-info {
  position: absolute;
  bottom: 0;
  height: 90%;
  width: 90%;
  border-radius: 0.5rem;
  background: rgb(226, 231, 238);
  opacity: 0;
  transition: all 0.5s ease;
  padding: 0.5rem;
}
.brand-card .button, .brand-card .contact-button, .brand-card .small-button {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.brand-card:hover .brand-info {
  opacity: 0.9;
  top: 50%;
  transform: translateY(-50%);
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-card:hover .brand-info {
    bottom: 1rem;
  }
}

/* brand page*/
.brand-container {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 1rem;
  width: 100%;
  height: 100vh;
  position: absolute;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-container {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brand-container {
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .brand-container {
    grid-template-columns: 50% 50%;
  }
}

.brand-left-distributor {
  grid-template-rows: 1fr 3fr 1fr 3fr;
}

.brand-left {
  padding: 9em 4em 0 4em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-left {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 50%;
    height: auto;
    max-height: 70%;
    overflow-y: auto;
    width: 90%;
    left: 50%;
    padding: 2rem 1rem;
    border: 3px solid hsl(0, 0%, 20%);
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    transform: translate(-50%, -50%);
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .brand-left {
    padding: 6em 1em;
    width: 100%;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brand-left {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 50%;
    height: auto;
    max-height: 70%;
    overflow-y: auto;
    width: 90%;
    left: 50%;
    padding: 2rem 1rem;
    border: 3px solid hsl(0, 0%, 20%);
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    transform: translate(-50%, -50%);
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .brand-left {
    padding: 9em 2em;
  }
}
.brand-left .logo {
  margin-left: 3em;
}
.brand-left .logo img {
  max-width: 100%;
  max-height: 150px;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-left .logo {
    margin-left: 0;
  }
  .brand-left .logo img {
    max-height: 75px;
  }
}
.brand-left .brand-information {
  margin: 3rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-left .brand-information {
    margin: 0;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .brand-left .brand-information {
    margin: 0;
  }
}
.brand-left .brand-information-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  margin-bottom: 2rem;
}
.brand-left .brand-information-container .button, .brand-left .brand-information-container .contact-button, .brand-left .brand-information-container .small-button {
  bottom: 0;
  right: 1rem;
}

.brand-link {
  position: absolute;
  bottom: 0;
  right: 0;
}

.brand-right {
  height: 100vh;
  position: fixed;
  right: 0;
  max-width: 40%;
}
.brand-right img {
  height: 100%;
  object-fit: cover;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-right {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brand-right {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .brand-right {
    max-width: 50%;
  }
}

.brand-product-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  border-bottom: 1px solid hsl(217, 65%, 32%);
  padding-bottom: 1rem;
}
.brand-product-container img {
  max-width: 100%;
  object-fit: cover;
}
.brand-product-container .product-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.brand-product-container .product-info ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-left: 1rem;
}

.brand-ul-list {
  list-style-type: disc;
}
.brand-ul-list li {
  margin-left: 2em;
}

/*Brand navigation*/
.nav-menu-parent {
  position: relative;
}

.brand-nav {
  position: absolute;
  top: 2.25rem;
  background-color: rgba(20, 48, 93, 0.8);
  padding: 1rem;
  opacity: 0;
  width: auto;
  transition: opacity 350ms ease-out;
  display: none;
}

.brand-nav-open {
  display: block;
  top: 4rem;
  opacity: 1;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-nav-open {
    position: fixed;
    top: unset;
    bottom: 3rem;
    left: 0;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .brand-nav-open {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .brand-nav-open {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
}

.brand-nav-category {
  margin-bottom: 0.5rem;
  color: hsl(7, 90%, 78%);
  font-size: var(--fs-300);
  cursor: pointer;
}
.brand-nav-category ul {
  max-height: 0px;
  opacity: 0;
  transition: opacity 350ms ease-out;
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
@media (orientation: portrait) and (max-width: 600px) {
  .brand-nav-category ul {
    grid-template-columns: 1fr 1fr;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .brand-nav-category ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.brand-nav-category ul li {
  display: none;
}

.brand-nav-name {
  color: rgb(226, 231, 238);
}
.brand-nav-name:hover {
  color: hsl(217, 65%, 72%);
}

.brand-nav-active-category .brand-nav-category-title {
  font-weight: bold;
}
.brand-nav-active-category ul {
  max-height: 1500px;
  opacity: 1;
}
.brand-nav-active-category ul li {
  display: block;
  margin-top: 0.4rem;
  padding: 0.1rem 0.5rem;
  white-space: nowrap;
}

.arrow-nav {
  position: fixed;
  bottom: 5rem;
  height: 3rem;
  width: 100%;
  display: flex;
  gap: 3em;
  justify-content: space-between;
  padding: 0 3em;
}
@media (orientation: portrait) and (max-width: 600px) {
  .arrow-nav {
    font-size: var(--fs-500);
    top: 10%;
    width: 100%;
    transform: none;
    left: unset;
    justify-content: space-between;
    padding: 1.5em 1em;
    gap: unset;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .arrow-nav {
    top: 5em;
  }
}
.arrow-nav svg {
  height: 30px;
  stroke: hsl(0, 0%, 90%);
  stroke-width: 1;
  fill: hsl(0, 0%, 20%);
}
.arrow-nav .left-arrow-nav .arrow-container,
.arrow-nav .right-arrow-nav .arrow-container {
  background: rgba(51, 51, 51, 0.8);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0.5em;
  display: flex;
  padding: 0.5em 1em;
}
.arrow-nav .left-arrow-nav .arrow-container a,
.arrow-nav .right-arrow-nav .arrow-container a {
  color: hsl(0, 0%, 90%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
}
@media (orientation: portrait) and (max-width: 600px) {
  .arrow-nav .left-arrow-nav .arrow-container a,
  .arrow-nav .right-arrow-nav .arrow-container a {
    font-size: var(--fs-200);
  }
}
.arrow-nav .left-arrow-nav .arrow-container:hover,
.arrow-nav .right-arrow-nav .arrow-container:hover {
  background: rgba(53, 114, 212, 0.8);
  color: rgb(226, 231, 238);
}
.arrow-nav .left-arrow-nav .arrow-container:hover a span,
.arrow-nav .right-arrow-nav .arrow-container:hover a span {
  color: rgb(226, 231, 238);
  font-weight: 500;
}

.ReactModal__Body--open {
  overflow-y: scroll;
}

.ReactModal__Overlay {
  z-index: 10;
  background-color: rgba(20, 48, 93, 0.75) !important;
  opacity: 0;
  transition: opacity 750ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  position: absolute;
  width: auto;
  height: 80%;
  inset: unset !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  border-radius: 1rem !important;
  background-color: rgb(226, 231, 238);
  overflow: hidden;
  padding: 1rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal {
    width: 90%;
    height: 90%;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .modal {
    width: 90%;
    height: 70%;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .modal {
    width: 90%;
    height: 90%;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .modal {
    width: 90%;
    height: 90%;
  }
}

.modal-title {
  margin-top: 0.5rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-title {
    margin-top: 0;
  }
}

.modalClose {
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.modal-item-container {
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-item-container {
    display: flex;
    flex-direction: column;
  }
}

.modal-right-side,
.modal-left-side {
  width: 100%;
  height: 90%;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-right-side,
  .modal-left-side {
    height: 50%;
  }
}

.modal-left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.modal-images-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.modal-images-container .modal-image {
  display: flex;
  justify-content: center;
  height: 75%;
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .modal-images-container .modal-image {
    height: 55%;
  }
}
.modal-images-container .main-image {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-images-container .modal-gallery-images {
  display: grid;
  grid-auto-flow: column;
  gap: 0.25rem;
  width: 100%;
  overflow: hidden;
}
.modal-images-container .modal-gallery-images .modal-gallery-image {
  width: 100%;
  max-height: 150px;
  cursor: pointer;
  object-fit: contain;
}

.modal-heading {
  display: flex;
  width: 100%;
  border-bottom: 1px solid hsl(217, 65%, 32%);
  justify-content: space-between;
  align-items: baseline;
  padding-top: 0.5rem;
}

.modal-heading-section {
  padding-top: 1rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-heading-section {
    flex-direction: column;
    padding-top: 0;
  }
}

.modal-item-text {
  height: 95%;
  overflow-y: auto;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-item-text {
    height: 50%;
    overflow-y: auto;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .modal-item-text {
    max-height: 215px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.modal-right-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.modal-contact-button-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.modal-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.modal-left {
  float: left;
  margin: 0 1rem 1rem 0;
}

.modal-right {
  float: right;
  margin: 0 0 1rem 1rem;
}

.modal-item-text .news-modal {
  width: 60%;
  padding: 2rem 6rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .modal-item-text .news-modal {
    width: 80%;
    padding: 1rem 2rem;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .modal-item-text .news-modal {
    width: 80%;
  }
}

.news-modal-item-container {
  height: calc(100% - 3rem);
}
@media (orientation: portrait) and (max-width: 600px) {
  .news-modal-item-container {
    padding-top: 1rem;
  }
}

.news-modal-item-text {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}
.news-modal-item-text p {
  text-wrap: pretty;
}
.news-modal-item-text iframe {
  width: 90%;
  height: auto;
  aspect-ratio: 16/9;
}
.news-modal-item-text br {
  content: "";
  display: block;
  margin: 1rem 0;
}
.news-modal-item-text img {
  max-width: 100%;
  min-width: 40%;
  height: auto;
  border-radius: 0.5rem;
}
.news-modal-item-text p {
  text-wrap: balance;
}
@media (orientation: portrait) and (max-width: 600px) {
  .news-modal-item-text {
    padding-bottom: 5rem;
  }
  .news-modal-item-text figure {
    max-width: 100%;
  }
}

main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  main {
    gap: 1rem;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  main {
    gap: 1rem;
  }
}

:root {
  --grid-size: 250px;
}
@media (min-width: 1920px) {
  :root {
    --grid-size: 300px;
  }
}
@media (min-width: 2560px) {
  :root {
    --grid-size: 400px;
  }
}

.shop-container {
  width: 90%;
}
@media (min-width: 1920px) {
  .shop-container {
    max-width: 1664px;
  }
}

.category-filters {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}
@media (orientation: portrait) and (max-width: 600px) {
  .category-filters {
    gap: 1rem;
    flex-wrap: wrap;
  }
}

.category-filter {
  color: hsl(0, 0%, 40%) !important;
  cursor: pointer;
  font-weight: 100 !important;
  transition: all 350ms;
}

.category-filter-active {
  color: hsl(217, 65%, 32%) !important;
  text-shadow: 1px 0 hsl(0, 0%, 40%);
}

.sale-items-container-grid {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-size), 1fr));
  gap: 1rem;
  width: 100%;
  margin-bottom: 5rem;
}

.sale-items-container-list {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: 5rem;
}

.for-sale-item-list,
.for-sale-item-sold {
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid hsl(217, 65%, 32%);
}
.for-sale-item-list:nth-child(even),
.for-sale-item-sold:nth-child(even) {
  background-color: hsl(0, 0%, 80%);
}
.for-sale-item-list .item,
.for-sale-item-sold .item {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.for-sale-item-list .item-details,
.for-sale-item-sold .item-details {
  width: 100%;
}
.for-sale-item-list .item-details .item-heading,
.for-sale-item-sold .item-details .item-heading {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.for-sale-item-list .item-details .item-title-date,
.for-sale-item-sold .item-details .item-title-date {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.for-sale-item-list .item-details .item-price,
.for-sale-item-sold .item-details .item-price {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.item-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: var(--fs-300);
}
@media (orientation: portrait) and (max-width: 600px) {
  .item-title {
    font-size: var(--fs-600);
  }
}

.for-sale-item-sold {
  background-color: hsl(7, 90%, 88%) !important;
}

.for-sale-item-grid {
  position: relative;
  border-radius: 1em;
  background-color: hsl(0, 0%, 90%);
  box-shadow: 5px 5px 6px -1px rgba(0, 0, 0, 0.24);
  display: flex;
  border: 1px solid hsl(0, 0%, 20%);
  overflow: hidden;
}
.for-sale-item-grid .item {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  cursor: pointer;
}
.for-sale-item-grid .item .item-image {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 250px;
  background-color: hsl(0, 0%, 20%);
}
.for-sale-item-grid .item .item-image img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}
.for-sale-item-grid .item .item-details {
  padding: 10px;
  border-bottom: 1px solid hsl(0, 0%, 20%);
  height: auto;
}
@media (orientation: portrait) and (max-width: 600px) {
  .for-sale-item-grid .item .item-details {
    padding-bottom: 4em;
  }
}
.for-sale-item-grid .item .item-details .item-heading {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  height: 3em;
}
.for-sale-item-grid .item .item-details .item-text {
  display: none;
}
.for-sale-item-grid .item .item-details .item-date-price-grid {
  display: flex;
  justify-content: flex-start;
}
.for-sale-item-grid .item .item-details .item-date-price-grid .item-date {
  margin-left: 0;
}
.for-sale-item-grid .item .item-details .item-price {
  font-size: var(--fs-300);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
@media (orientation: portrait) and (max-width: 600px) {
  .for-sale-item-grid .item .item-details .item-price {
    font-size: var(--fs-500);
  }
}

.for-sale-item-grid-sold {
  background: hsl(7, 90%, 88%);
}

.item-date {
  font-size: var(--fs-100);
  font-weight: 300;
  font-style: italic;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: inline-block;
}

.search-field {
  border: 1px solid hsl(217, 65%, 32%);
  border-radius: 0.25rem;
  font-size: var(--fs-100);
  padding: 0.2rem 0.5rem;
}

.turntable-mat-container {
  height: 19rem;
}

.turntable-mat-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 0.5rem 0;
  gap: 1rem;
  font-size: var(--fs-200);
}
.turntable-mat-info img {
  max-height: 19rem;
  max-width: 100%;
  width: auto;
}
.turntable-mat-info .right-side {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 20rem;
  overflow-y: auto;
}
.turntable-mat-info .right-side .flex .button a, .turntable-mat-info .right-side .flex .contact-button a, .turntable-mat-info .right-side .flex .small-button a {
  color: rgb(226, 231, 238);
}

/* Specials */
.site-specials-container {
  position: relative;
  height: 20rem;
  margin-bottom: 1rem;
}

.site-special {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  opacity: 0;
  transition: opacity 1650ms ease;
  z-index: -1;
  margin-top: 0.5rem;
  width: 100%;
}
.site-special .site-special-left img {
  max-height: 20rem;
  max-width: 100%;
  width: auto;
}
.site-special .site-special-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 20rem;
  font-size: var(--fs-200);
  max-height: 85%;
  overflow-y: auto;
}
.site-special .flex {
  position: absolute;
  bottom: 0;
  right: 0;
}
.site-special .flex .contact-button a {
  color: white;
}

.site-special-active {
  opacity: 1;
  z-index: 1;
}

.site-slider-circle {
  height: 1.25rem;
  width: 1.25rem;
  opacity: 0.7;
  background-color: hsl(0, 0%, 90%);
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 50%;
}
.site-slider-circle:hover {
  cursor: pointer;
}

.site-slider-circle-active {
  background-color: hsl(0, 0%, 60%);
  border: 1px solid hsl(0, 0%, 30%);
}

.about-container {
  width: 90%;
  max-width: 1314px;
}
@media (min-width: 1920px) {
  .about-container {
    max-width: 1664px;
  }
}

.about-info-section,
.about-showroom-section {
  margin-top: 1rem;
}

.about-info-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 35vh;
  overflow: hidden;
}
@media (orientation: portrait) and (max-width: 600px) {
  .about-info-section {
    grid-template-columns: 1fr;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .about-info-section {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
}

.about-info-left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  margin-inline: 1rem;
}
.about-info-left-side iframe {
  width: 90%;
  height: 100%;
}

.about-info-right-side {
  padding: 1rem;
}
.about-info-right-side img {
  max-width: 100%;
}

.about-info-heading {
  font-family: "EB Garamond", serif;
  color: hsl(217, 65%, 32%);
  font-size: var(--fs-400);
}

.about-showroom-info-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1rem;
}
.about-showroom-info-section img {
  padding: 1rem;
  max-width: 100%;
}
.about-showroom-info-section ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin: 1rem;
}

@media (orientation: portrait) and (max-width: 600px) {
  .about-info-right-side img {
    max-width: 100%;
  }
}
:root {
  height: 100%;
  --fs-50: 0.6rem;
  --fs-80: 0.7rem;
  --fs-100: 0.8rem;
  --fs-200: 0.9rem;
  --fs-300: 1rem;
  --fs-400: 1.2rem;
  --fs-500: 1.4rem;
  --fs-600: 1.6rem;
  --fs-700: 1.8rem;
  --fs-800: 2rem;
  --fs-1000: 3rem;
}

.text-50 {
  font-size: var(--fs-50);
}

.text-80 {
  font-size: var(--fs-80);
}

.text-100 {
  font-size: var(--fs-100);
}

.text-200 {
  font-size: var(--fs-200);
}

.text-400 {
  font-size: var(--fs-400);
}

.text-500 {
  font-size: var(--fs-500);
}

.text-600 {
  font-size: var(--fs-600);
}

.text-700 {
  font-size: var(--fs-700);
}

.text-800 {
  font-size: var(--fs-800);
}

.text-1000 {
  font-size: var(--fs-1000);
}

body,
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: hsl(0, 0%, 20%);
  height: 100%;
  padding-bottom: 5rem;
  font-size: 16px;
  user-select: none;
}
@media (orientation: portrait) and (max-width: 600px) {
  body,
  html {
    font-size: 13px;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  body,
  html {
    font-size: 14px;
  }
}
@media (min-width: 1920px) {
  body,
  html {
    font-size: 16px;
  }
}
@media (min-width: 2560px) {
  body,
  html {
    font-size: 20px;
  }
}

body {
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: hsl(217, 65%, 32%);
}

main {
  width: 100%;
}

.page-container {
  padding: 9em 0;
  position: absolute;
}
@media (orientation: portrait) and (max-width: 600px) {
  .page-container {
    padding: 7rem 0;
  }
}
@media (orientation: landscape) and (min-width: 500px) and (max-width: 950px) {
  .page-container {
    padding: 8rem 0;
  }
}
@media (orientation: portrait) and (min-width: 601px) and (max-width: 1439px) {
  .page-container {
    padding: 9rem 0;
  }
}
@media (orientation: landscape) and (min-width: 601px) and (max-width: 1439px) {
  .page-container {
    padding: 9em 0;
  }
}

.button, .small-button, .contact-button {
  padding: 0.5rem 1rem;
  background-color: hsl(217, 65%, 32%);
  border-radius: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: var(--fs-100);
  font-weight: bold;
  border: 2px solid hsl(0, 0%, 20%);
  color: rgb(226, 231, 238);
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 34px;
  margin-top: 0.5rem;
  white-space: nowrap;
  text-align: center;
}
.button:hover, .small-button:hover, .contact-button:hover {
  cursor: pointer;
  background-color: hsl(217, 65%, 52%);
}

.contact-button {
  background-color: hsl(7, 90%, 58%);
}
.contact-button:hover {
  background-color: hsl(7, 90%, 78%);
  cursor: pointer;
}
.contact-button a {
  color: rgb(226, 231, 238);
}

.small-button {
  font-size: var(--fs-80);
}
@media (orientation: portrait) and (max-width: 600px) {
  .small-button {
    height: 22px;
    padding: 0.2rem 0.4rem;
  }
}

.dark-blue-text {
  color: hsl(217, 65%, 32%);
}

.section-heading {
  font-size: var(--fs-600);
  font-family: "EB Garamond", serif;
  font-weight: bold;
  color: hsl(217, 65%, 32%);
}

.section-subheading {
  color: hsl(217, 65%, 32%);
  font-family: "EB Garamond", serif;
  font-weight: bold;
  font-size: var(--fs-400);
}

.heading-section {
  border-bottom: 1px solid #14305d;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.flex {
  display: flex;
  gap: 1rem;
}

.flex-right {
  justify-content: right;
}
/*transitions*/
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 850ms ease-in-out;
  transition-delay: 500ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 420ms ease-out;
}